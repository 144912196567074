/* Navbar Styles */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 90px;
    background-color: #000000;
    color: white;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.navbar-logo {
    height: 22px;
}

/* Menu Icon and Button Container Styles */
.menu-icon-container,
.button-container {
    position: absolute;
}

.menu-icon-container {
    left: 20px;
}

.button-container {
    right: 20px;
}

/* Menu Icon Styles */
.menu-icon {
    height: 22px;
    cursor: pointer;
}

/* Book Appointment Button Styles */
.book-appointment-btn {
    background-color: transparent; /* Changed to transparent */
    color: white; /* Changed to white */
    padding: 10px 20px;
    border: 0.5px solid white; /* Added white border */
    border-radius: 0px;
    cursor: pointer;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Custom Drawer Styles */
.custom-drawer .ant-drawer-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.custom-drawer .ant-drawer-title {
    justify-content: flex-start;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {

    .button-container {
        right: 4px; /* Adjust as needed to move to the right */
        top: 25px;
    }

    .book-appointment-btn {
        /* margin-right: -10px; */
    }

    .custom-drawer .ant-drawer-body,
    .custom-drawer .ant-drawer-title {
        justify-content: flex-start;
    }
}
