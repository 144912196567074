/* BookingForm.css */
.booking-form .ant-form-item-label>label {
  color: white !important;
  /* Override label color */
}

.booking-form .ant-form-item-explain,
.booking-form .ant-form-item-extra {
  color: white !important;
  /* Override error message and extra text */
}

/* If you want to change the input placeholder color, it's a bit trickier and requires targeting the ::placeholder pseudo-element */
.booking-form .ant-input::placeholder,
.booking-form .ant-select-selection-placeholder,
.booking-form .ant-picker-input input::placeholder {
  color: white !important;
  /* Override placeholder text color */
}

/* Override text color for selected items in inputs */
.booking-form .ant-input,
.booking-form .ant-select-single .ant-select-selector .ant-select-selection-item,
.booking-form .ant-picker-input input {
  color: black !important;
}

/* Ensure selected option text is visible */
.ant-select-selection-item,
.ant-picker-input input {
  color: black !important;
  /* Adjust color as needed */
}

/* Improve visibility for placeholders */
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
.ant-picker input {
  color: rgba(255, 255, 255, 0.65) !important;
  /* Light grey for visibility */
}

/* Extend full width for DatePicker and TimePicker */
.booking-form .ant-picker, .booking-form .ant-select {
  width: 100%;
}

/* Additional style to ensure consistency and full width */
.booking-form .ant-form-item-control-input-content {
  width: 100%; /* Ensure that the content inside form item control also takes full width */
}

.book-appt-btn {
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  border: 0.5px solid white;
  border-radius: 0px;
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-input {
  width: 100%;
  background-color: white; 
  color: black;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .booking-form .ant-form-item {
    width: 100%;
    /* Full width on smaller screens */
  }
}

.booking-form .ant-form-item-control-input {
  width: 100%;
  /* Ensures input controls take up full container width */
}