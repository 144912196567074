/* About.css */
.about-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    min-height: 100vh;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.about-header {
    font-size: 2.2em;
    margin-bottom: 20px;
    z-index: 1;
    font-weight: 100;
    text-transform: uppercase; 
    max-width: 80%;
    line-height: -2%;
    line-height: 100%;
}

.about-content {
    max-width: 800px;
    line-height: 1.6;
    z-index: 1;
    padding: 0 20px;
    text-align: center;
    max-width: 80%;
    font-weight: 300;
}
