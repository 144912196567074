.services {
    padding: 4rem 2rem;
    background-color: #000000;
    color: #ffffff;
  }
  
  .services-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 3rem;
    color: #ffffff;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .service-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .service-item {
    background-color: #1a1a1a;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(255, 255, 255, 0.15);
  }
  
  .service-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #e0e0e0;
    font-weight: 600;
  }
  
  .service-item p {
    color: #b0b0b0;
    line-height: 1.6;
  }