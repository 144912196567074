.clients-container {
    display: flex;
    flex-direction: column; /* Added to stack the children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    min-height: 100vh; /* Ensure container takes up at least the full height of the viewport */
    padding: 20px;
    color: white; /* Make the text color white */
    background-color: rgba(0, 0, 0, 0.5);
}

h1 {
    margin-bottom: 40px; /* Add some space between the title and the client names */
    color: white; 
    font-size: 2.2em;
    text-transform: uppercase;
    font-weight: 200;
}

.client-name {
    font-size: 12px;  /* Small text */
    text-transform: uppercase;  /* All uppercase */
    font-weight: bold;  /* Bold text */
    margin: 5px 10px;  /* Space between names */
    color: white; /* Make the client names text color white */
}
