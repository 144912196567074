

.ant-typography {
  color: #d4af37 !important;
}

.bg-gold {
  background-color: #d4af37 !important;
}

.text-gold {
  color: #d4af37 !important;
}

.border-gold {
  border-color: #d4af37 !important;
}

.hover\:bg-gold-dark:hover {
  background-color: #b8860b !important;
}

.hover\:border-gold-dark:hover {
  border-color: #b8860b !important;
}

.ant-input-affix-wrapper > input.ant-input {
  background-color: transparent;
  color: white;
}

.ant-form-item-label > label {
  color: #d4af37;
}

.contact-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  padding: 2rem;
}

.contact-form-container {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-title {
  color: #ffffff;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'Arial', sans-serif;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: hsl(0, 0%, 100%);
  font-size: 0.9rem;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 0.75rem;
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(212, 175, 55, 0.5);
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.submit-button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .contact-form-container {
    padding: 1.5rem;
  }
}