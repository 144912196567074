/* Existing styles */
.content {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.content h1 {
    text-transform: uppercase; 
    font-size: 2.2em;
    font-weight: 200;
    margin-bottom: -8px;
    line-height: 100%;
}

.heroSection {
    overflow: hidden;
    padding-top: 90px;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    color: white;
}

button {
    border-radius: 0px;
    transition: 0.2s ease-in;
}

.box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    transition: flex 0.3s ease-in;
}

.firstBox {
    background-image: url('../assets/first-box-background.jpg');
    background-size: cover;
    background-position: center;
}

.secondBox {
    background-image: url('../assets/second-box-background.jpg');
    background-size: cover;
    background-position: center;
}

.thirdBox {
    background-image: url('../assets/third-box-background.jpg');
    background-size: cover;
    background-position: center;
}

.fourthBox {
    background-image: url('../assets/fourth-box-background.jpg');
    background-size: cover;
    background-position: center;
}

.active {
    flex: 8;
}

.hover {
    flex: 1.5;
}

/* Media query */
@media (max-width: 768px) {
    .heroSection {
        flex-direction: column;
        height: auto;
    }

    .box {
        width: 100vw;
        height: 100vw;
        flex: none;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; /* Added this line to center content vertically */
        opacity: 1 !important;
        transform: translateX(0) !important;
        width: 80%;
        text-align: center;
    }
}
